import React from 'react';
import ReactMarkdown from 'react-markdown';
import Img from 'gatsby-image';
import Button from 'aphrodite-shared/ui/Button/Button';
import {
    BasicCircle,
    BasicDoughnut,
    LongArch
} from '../../../../SVGComponents/shapes';
import styles from './MeetIwoca.module.scss';
import { useIntl } from 'react-intl';
import messages from '../Careers.messages';
import { getCountryBuild } from '../../../../util/localization';

const MeetIwoca = ({
    title,
    content,
    bubbleImage1,
    bubbleImage2,
    bubbleImage3,
    profileImage,
    quote,
    quoted
}) => {
    const { formatMessage } = useIntl();
    const aboutUsLink =
        getCountryBuild() === 'de' ? '/ueber_uns/' : '/about-us/';
    return (
        <section>
            <div className={styles.MeetIwoca}>
                <div className={styles.content}>
                    <h2>{title}</h2>
                    <hr />
                    <ReactMarkdown source={content} />
                    <Button
                        colour="secondary"
                        href={aboutUsLink}
                        className={styles.readAboutUs}
                        dataGaId="careers__read_about_us">
                        {formatMessage({
                            ...messages.read_about_us
                        })}
                    </Button>
                </div>
                <div className={styles.imagesContainer}>
                    <div className={styles.bubbleImage1}>
                        <BasicCircle
                            size="xs"
                            className={styles.BasicCircle}
                            color="london"
                        />
                        <Img fluid={bubbleImage1.fluid} />
                    </div>
                    <Img
                        fluid={bubbleImage2.fluid}
                        className={styles.bubbleImage2}
                    />
                    <Img
                        fluid={bubbleImage3.fluid}
                        className={styles.bubbleImage3}
                    />
                </div>
            </div>
            <div className={styles.MeetIwoca}>
                <div className={styles.profileContainer}>
                    <BasicDoughnut
                        color="london75"
                        className={styles.BasicDoughnut}
                    />
                    <LongArch rotate="right" className={styles.LongArch} />
                    <Img
                        fluid={profileImage.fluid}
                        className={styles.ProfileImage}
                    />
                </div>
                <div className={styles.content}>
                    <ReactMarkdown source={quote} className={styles.quote} />
                    <p className={styles.quoted}>{quoted}</p>
                </div>
            </div>
        </section>
    );
};

export default MeetIwoca;
