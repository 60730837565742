import React, { useState } from 'react';
import Chevron from '../../../../../SVGComponents/Chevron';
import styles from './JobListing.module.scss';
import classnames from 'classnames';

const JobListing = ({ jobs, cities, department }) => {
    const [selectedCity, setCity] = useState(null);
    let jobCityTable = {};

    jobs.forEach((job) => {
        // Place all the jobs in their respective city
        let currCity = job.location.telecommuting
            ? 'Remote'
            : job.location.city;

        if (currCity !== job.location.country) {
            // We want to not show the jobs that are country wide and in their case country === city
            if (jobCityTable[currCity]) {
                jobCityTable[currCity].push(job);
            } else {
                jobCityTable[currCity] = [job];
            }
        }
    });

    const getJobs = () => {
        return (
            <>
                {jobCityTable[selectedCity]
                    ? jobCityTable[selectedCity].map((job) => (
                          <JobListItem job={job} />
                      ))
                    : jobs.map((job) => <JobListItem job={job} />)}
            </>
        ); // if city is undefined then just fetch all the jobs
    };

    const setButtonClass = (city) =>
        city === selectedCity ? styles.ActiveButton : '';

    return (
        <div className={styles.JobListing}>
            <div className={styles.ListingDepartment}>
                <h3>{department}</h3>
                <span className={styles.citySelector}>
                    {cities.map((city) => {
                        if (jobCityTable[city]) {
                            return (
                                <button
                                    className={classnames(
                                        styles.cityButton,
                                        setButtonClass(city)
                                    )}
                                    onClick={() => setCity(city)}>
                                    {`${city} (${jobCityTable[city].length})`}
                                </button>
                            );
                        } else {
                            return (
                                <button
                                    className={styles.inactiveCityButton}
                                    disabled
                                    onClick={() => setCity(city)}>
                                    {city}
                                </button>
                            );
                        }
                    })}
                </span>
            </div>
            <ul className={styles.List}>{getJobs()}</ul>
        </div>
    );
};

const JobListItem = ({ job }) => {
    return (
        <li className={styles.JobListItem}>
            <a href={job.url} className={styles.JobListLink}>
                <div className={styles.JobItemText}>
                    <h5>{job.title}</h5>
                    <p>{`${job.location.city}, ${job.location.country}`}</p>
                </div>
                <Chevron className={styles.JobItemChevron} />
            </a>
        </li>
    );
};

export default JobListing;
