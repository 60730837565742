import React, { useState } from 'react';
import styles from './MobileDeptSelector.module.scss';
import Chevron from '../../../../../SVGComponents/Chevron';
import classnames from 'classnames';

const MobileDeptSelector = ({ departments, setDepartment, jobTable }) => {
    const [activeList, setActiveList] = useState(false);
    const [currDepartment, setCurrDepartment] = useState('All');

    const containerStyle = activeList ? styles.ActiveContainer : '';
    const listStyle = activeList ? styles.ActiveDeptList : '';
    const buttonStyle = activeList ? styles.ActiveSelector : '';

    if (typeof document !== 'undefined') {
        if (activeList) {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto';
        }
    }

    const handleListItemClick = (department) => {
        setDepartment(department);
        setCurrDepartment(department);
        setActiveList(false);
    };

    return (
        <div className={classnames(styles.Container, containerStyle)}>
            <button
                className={classnames(styles.MobileDeptSelector, buttonStyle)}
                onClick={() => setActiveList(!activeList)}>
                <h5 className={styles.SelectorTitle}>
                    {currDepartment}{' '}
                    <span
                        className={
                            styles.departmentCount
                        }>{`(${jobTable[currDepartment].length})`}</span>
                </h5>
                <Chevron className={styles.Chevron} />
            </button>
            <div className={classnames(styles.ListContainer, listStyle)}>
                {departments.map((department) => {
                    return (
                        <button
                            className={styles.DeptListButton}
                            onClick={() => handleListItemClick(department)}>
                            <h5>
                                {department}{' '}
                                <span
                                    className={
                                        styles.departmentCount
                                    }>{`(${jobTable[department].length})`}</span>
                            </h5>
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export default MobileDeptSelector;
