import React, { useState } from 'react';
import Button from 'aphrodite-shared/ui/Button/Button';
import { BasicSquare, WedgedArch } from '../../../../SVGComponents/shapes';
import { Link } from 'react-scroll';
import styles from './Values.module.scss';
import { useIntl } from 'react-intl';
import messages from '../Careers.messages';

const Values = ({ values }) => {
    const { formatMessage } = useIntl();
    let [activeSlide, setActiveSlide] = useState(0);
    return (
        <section className={styles.background}>
            <WedgedArch className={styles.WedgedArch} />
            <BasicSquare className={styles.BasicSnowSquare} color="snow" />
            <BasicSquare
                className={styles.BasicCoralSquare}
                rotate="right"
                size="sm"
            />
            <div className={styles.Values}>
                <h2>
                    {formatMessage({
                        ...messages.our_values
                    })}
                </h2>
                <hr />
                <div className={styles.actionsContainer}>
                    {values.map((value, i) => (
                        <button
                            className={
                                i === activeSlide ? `${styles.active}` : ''
                            }
                            onClick={() => setActiveSlide(i)}
                            key={i}>
                            {value.name}
                        </button>
                    ))}
                </div>
                <div className={styles.slideContainer}>
                    {values.map((value, i) => (
                        <div
                            className={
                                i === activeSlide
                                    ? `${styles.slide} ${styles.active}`
                                    : `${styles.slide}`
                            }
                            key={i}
                            data-testid="values-slide">
                            <h3>{value.name}</h3>
                            <p>{value.description}</p>
                        </div>
                    ))}
                </div>
                <div>
                    <Link
                        offset={-50}
                        to="AllJobs"
                        index="1"
                        duration={500}
                        smooth={true}>
                        <Button colour="secondary">
                            {formatMessage({
                                ...messages.see_all_jobs
                            })}
                        </Button>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default Values;
