import React from 'react';

const PlayButton = () => {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                x="1"
                y="1"
                width="28"
                height="28"
                rx="14"
                fill="black"
                fill-opacity="0.21"
                stroke="white"
                stroke-width="2"
            />
            <path
                d="M18.3848 14.7599L13.1924 10.0002L13.1924 19.5195L18.3848 14.7599Z"
                fill="white"
            />
        </svg>
    );
};

export default PlayButton;
