import React from 'react';

const ApproveRing = ({ className }) => {
    return (
        <svg
            className={className}
            width="78"
            height="79"
            viewBox="0 0 78 79"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="39"
                cy="39.541"
                r="35"
                stroke="#51B15D"
                stroke-width="8"
            />
        </svg>
    );
};

export default ApproveRing;
