import React from 'react';
import Button from 'aphrodite-shared/ui/Button/Button';
import styles from './CantFindLookingFor.module.scss';
import { useIntl } from 'react-intl';
import messages from '../Careers.messages';

const CantFindLookingFor = ({ title, content }) => {
    const { formatMessage } = useIntl();
    return (
        <section className={styles.background}>
            <div className={styles.CantFindLookingFor}>
                <h3>{title}</h3>
                <p>{content}</p>
                <Button
                    colour="primary"
                    href="https://apply.workable.com/iwoca/j/6B1BA5B936/"
                    newTab="_blank">
                    {formatMessage({
                        ...messages.apply
                    })}
                </Button>
            </div>
            {/* <BasicGrid className={styles.BasicGrid} /> */}
        </section>
    );
};

export default CantFindLookingFor;
