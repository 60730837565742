import React, { useState } from 'react';
import CloseIcon from '../../../../SVGComponents/CloseIcon';
import ArrowSVG from '../../../../SVGComponents/ArrowSVG';
import GatsbyImg from 'gatsby-image';
import Button from 'aphrodite-shared/ui/Button/Button';
import PlayButton from './PlayButtonSvg';
import styles from './AboveTheFold.module.scss';
import BarWidget from './BarWidget/BarWidget';
import { animateScroll as scroll, Link } from 'react-scroll';
import { useIntl } from 'react-intl';
import messages from '../Careers.messages';

const AboveTheFold = ({
    shortVideoUrl,
    fullVideoUrl,
    title,
    ctaText,
    jobs,
    mobileImage,
    barwidget
}) => {
    const { formatMessage } = useIntl();
    const [numOfJobs, numOfCities] = getJobsStats(jobs);
    const [isModalOpen, setOpenModal] = useState(false);

    const onArrowClick = () => {
        const innerHeight =
            typeof window !== 'undefined' ? window.innerHeight : '600';
        scroll.scrollMore(innerHeight);
    };

    return (
        <>
            <div className={styles.AboveTheFold}>
                <div className={styles.VideoContainer}>
                    <video className={styles.Video} autoPlay muted loop>
                        <source src={shortVideoUrl} type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                    <div className={styles.mobileImageContainer}>
                        <GatsbyImg
                            className={styles.mobileImage}
                            fluid={mobileImage.fluid}
                        />
                    </div>
                    <div className={styles.Content}>
                        <h1>{title}</h1>
                        <Link
                            offset={-50}
                            to="AllJobs"
                            index="1"
                            duration={500}
                            smooth={true}>
                            <Button
                                colour="secondary"
                                dataGaId="careers__see_all_jobs">
                                {ctaText}
                            </Button>
                        </Link>
                        <h6>
                            {numOfJobs}{' '}
                            <span className={styles.JobFactsText}>
                                {formatMessage({
                                    ...messages.openings_in
                                })}{' '}
                            </span>{' '}
                            {numOfCities}{' '}
                            <span className={styles.JobFactsText}>
                                {formatMessage({
                                    ...messages.locations
                                })}
                            </span>
                        </h6>
                        <div
                            className={styles.PlayBar}
                            onClick={() => setOpenModal(!isModalOpen)}>
                            <PlayButton />{' '}
                            <span className={styles.PlayBarText}>
                                {formatMessage({
                                    ...messages.see_life_at_iwoca
                                })}
                            </span>
                        </div>
                    </div>
                    <ArrowSVG className={styles.arrow} onClick={onArrowClick} />
                </div>
                <BarWidget {...barwidget} />
            </div>
            {isModalOpen && (
                <div className={styles.Modal}>
                    <CloseIcon
                        className={styles.CloseIcon}
                        onClick={() => setOpenModal(!isModalOpen)}
                    />
                    <video className={styles.PopupVideo} controls autoPlay>
                        <source src={fullVideoUrl} />
                        Your browser does not support HTML5 video.
                    </video>
                </div>
            )}
        </>
    );
};

const getJobsStats = (jobs) => {
    let cities = [];

    jobs.forEach((job) => {
        const city = job.location.city;
        if (!cities.includes(city) && city !== job.location.country) {
            cities.push(city);
        }
    });
    return [jobs.length, cities.length];
};

export default AboveTheFold;
