import React from 'react';

const FriendRing = ({ className }) => {
    return (
        <svg
            className={className}
            width="78"
            height="79"
            viewBox="0 0 78 79"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="39"
                cy="39.541"
                r="35"
                stroke="#E6E6E6"
                stroke-width="8"
            />
            <mask id="path-1-inside-1" fill="white">
                <path d="M78 39.541C78 48.5639 74.8714 57.3076 69.1474 64.2824C63.4234 71.2571 55.458 76.0314 46.6085 77.7916C37.759 79.5519 28.573 78.1893 20.6155 73.9359C12.6581 69.6826 6.4216 62.8017 2.9687 54.4657C-0.484199 46.1296 -0.93987 36.8543 1.67933 28.2199C4.29852 19.5856 9.83053 12.1265 17.3328 7.1137C24.835 2.10087 33.8433 -0.155583 42.8227 0.728811C51.8021 1.6132 60.197 5.58373 66.5772 11.9638L60.8816 17.6594C55.8192 12.5969 49.1581 9.44646 42.0332 8.74472C34.9083 8.04298 27.7605 9.83341 21.8077 13.8109C15.8549 17.7885 11.4654 23.707 9.38719 30.5581C7.30893 37.4091 7.67049 44.7689 10.4103 51.3833C13.15 57.9977 18.0985 63.4574 24.4125 66.8323C30.7265 70.2072 38.0153 71.2884 45.0371 69.8917C52.0589 68.495 58.3792 64.7068 62.921 59.1725C67.4629 53.6383 69.9453 46.7004 69.9453 39.541H78Z" />
            </mask>
            <path
                d="M78 39.541C78 48.5639 74.8714 57.3076 69.1474 64.2824C63.4234 71.2571 55.458 76.0314 46.6085 77.7916C37.759 79.5519 28.573 78.1893 20.6155 73.9359C12.6581 69.6826 6.4216 62.8017 2.9687 54.4657C-0.484199 46.1296 -0.93987 36.8543 1.67933 28.2199C4.29852 19.5856 9.83053 12.1265 17.3328 7.1137C24.835 2.10087 33.8433 -0.155583 42.8227 0.728811C51.8021 1.6132 60.197 5.58373 66.5772 11.9638L60.8816 17.6594C55.8192 12.5969 49.1581 9.44646 42.0332 8.74472C34.9083 8.04298 27.7605 9.83341 21.8077 13.8109C15.8549 17.7885 11.4654 23.707 9.38719 30.5581C7.30893 37.4091 7.67049 44.7689 10.4103 51.3833C13.15 57.9977 18.0985 63.4574 24.4125 66.8323C30.7265 70.2072 38.0153 71.2884 45.0371 69.8917C52.0589 68.495 58.3792 64.7068 62.921 59.1725C67.4629 53.6383 69.9453 46.7004 69.9453 39.541H78Z"
                stroke="#51B15D"
                stroke-width="22"
                mask="url(#path-1-inside-1)"
            />
        </svg>
    );
};

export default FriendRing;
