import React from 'react';
import ReactMarkdown from 'react-markdown';
import Img from 'gatsby-image';
import {
    BasicDoughnut,
    BasicGrid,
    BasicSquare
} from '../../../../SVGComponents/shapes';
import styles from './GrowingFast.module.scss';

const GrowingFast = ({ image, title, content }) => {
    return (
        <section>
            <div className={styles.GrowingFast}>
                <div className={styles.imageContainer}>
                    <BasicDoughnut
                        className={styles.BasicDoughnut}
                        color="snow"
                    />
                    <Img fluid={image.fluid} />
                    <BasicGrid className={styles.BasicGrid} />
                </div>
                <div className={styles.content}>
                    <BasicSquare className={styles.BasicSquare} color="denim" />
                    <h2>{title}</h2>
                    <hr />
                    <ReactMarkdown source={content} />
                </div>
            </div>
        </section>
    );
};

export default GrowingFast;
