import React from 'react';
import GatsbyImg from 'gatsby-image';
import FriendRing from './svg/FriendRing';
import ApproveRing from './svg/ApproveRing';
import { BasicSquare } from '../../../../SVGComponents/shapes';
import styles from './Reviews.module.scss';
import { useIntl } from 'react-intl';
import messages from '../Careers.messages';

const Reviews = ({ icon, approve, recommend }) => {
    const { formatMessage } = useIntl();
    return (
        <div className={styles.Reviews}>
            <BasicSquare
                className={styles.BasicSquare}
                size="sm"
                color="london"
            />
            <div className={styles.Icon}>
                <GatsbyImg fluid={icon.fluid} />
            </div>
            <div className={styles.Opinions}>
                <div className={styles.OpinionWrapper}>
                    <div className={styles.RingWrapper}>
                        <FriendRing />
                        <div className={styles.Percent}>{`${recommend}%`}</div>
                    </div>
                    <p>
                        {formatMessage({
                            ...messages.recommend_to_a_friend
                        })}
                    </p>
                </div>
                <div className={styles.Seperator} />
                <div className={styles.OpinionWrapper}>
                    <div className={styles.RingWrapper}>
                        <ApproveRing />
                        <div className={styles.Percent}>{`${approve}%`}</div>
                    </div>
                    <p>
                        {formatMessage({
                            ...messages.approve_of_ceo
                        })}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Reviews;
