import React from 'react';
import MetaData from '../../../components/Layout/MetaData.jsx';
import MeetIwoca from './MeetIwoca/MeetIwoca';
import GrowingFast from './GrowingFast/GrowingFast';
import WorkWithUs from './WorkWithUs/WorkWithUs';
import JobPosting from './JobPosting/JobPosting';
import Values from './Values/Values';
import Footer from '../../../components/Footer/Footer';
import Benefits from './Benefits/Benefits';
import CantFindLookingFor from './CantFindLookingFor/CantFindLookingFor';
import AboveTheFold from './AboveTheFold/AboveTheFold';
import Reviews from './Reviews/Reviews';
import { graphql } from 'gatsby';
import styles from '../iwocaPage.module.scss';
import WantToKnowMore from '../common/WantToKnowMore/WantToKnowMore';
import NavBar from '../../../components/NavBar/NavBar';

const Careers = ({ data }) => {
    const { contentfulPageCareers: pageData } = data;
    const {
        title,
        metaDescription,
        allowSearchEngineScraping = true,
        url
    } = pageData;

    const jobs = data.jobs ? data.jobs.edges.map((item) => item.node) : [];
    const values = [
        {
            name: pageData.motivatedTitle,
            description: pageData.motivatedDescription.motivatedDescription
        },
        {
            name: pageData.smartTitle,
            description: pageData.smartDescription.smartDescription
        },
        {
            name: pageData.humbleTitle,
            description: pageData.humbleDescription.humbleDescription
        },
        {
            name: pageData.growthMindsetTitle,
            description:
                pageData.growthMindsetDescription.growthMindsetDescription
        }
    ];

    return (
        <div className={styles.Page}>
            <NavBar />
            <MetaData
                metaData={{
                    titleTag: title,
                    metaDescription,
                    allowSearchEngineScraping,
                    url
                }}
            />
            <AboveTheFold
                shortVideoUrl={pageData.aboveTheFoldShortVideo.file.url}
                fullVideoUrl={pageData.aboveTheFoldFullVideo.file.url}
                mobileImage={pageData.aboveTheFoldMobileImage}
                title={pageData.aboveTheFoldTitle}
                ctaText={pageData.aboveTheFoldCtaText}
                jobs={jobs}
                barwidget={pageData.barwidget}
            />
            <MeetIwoca
                title={pageData.meetIwocaTitle}
                content={pageData.meetIwocaContent.meetIwocaContent}
                bubbleImage1={pageData.meetIwocaImage1}
                bubbleImage2={pageData.meetIwocaImage2}
                bubbleImage3={pageData.meetIwocaImage3}
                profileImage={pageData.meetIwocaProfile}
                quote={pageData.meetIwocaQuote.meetIwocaQuote}
                quoted={pageData.meetIwocaQuoted}
            />
            <Values values={values} />
            <GrowingFast
                image={pageData.growingImage}
                title={pageData.growingTitle}
                content={pageData.growingContent.growingContent}
            />
            <Benefits benefits={pageData.benefits} />
            <WorkWithUs
                title={pageData.workWithUsTitle}
                image1={pageData.workWithUsImage1}
                image2={pageData.workWithUsImage2}
                image3={pageData.workWithUsImage3}
                image4={pageData.workWithUsImage4}
                image5={pageData.workWithUsImage5}
                image6={pageData.workWithUsImage6}
            />
            <JobPosting jobs={jobs} title={pageData.jobBoardTitle} />
            <CantFindLookingFor
                title={pageData.cantFindJobTitle}
                content={pageData.cantFindJobContent}
            />
            <Reviews
                icon={pageData.reviewsGlassdoorIcon}
                recommend={pageData.reviewsRecommend}
                approve={pageData.reviewsApprove}
            />
            <WantToKnowMore
                faq={pageData.faqSection.faqs}
                title={pageData.faqSection.title}
                subtitle={pageData.faqSection.subtitle.subtitle}
            />
            <Footer />
        </div>
    );
};

export default Careers;

export const query = graphql`
    query CareersQuery($id: String!) {
        contentfulPageCareers(id: { eq: $id }) {
            title
            metaDescription
            allowSearchEngineScraping
            url
            benefits {
                icon {
                    fixed(width: 83, height: 100) {
                        ...GatsbyContentfulFixed
                    }
                }
                title
                description {
                    description
                }
            }
            aboveTheFoldShortVideo {
                file {
                    url
                }
            }
            aboveTheFoldFullVideo {
                file {
                    url
                }
            }
            aboveTheFoldTitle
            aboveTheFoldMobileImage {
                fluid(maxWidth: 600, quality: 80) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            navigation {
                ... on Node {
                    ... on ContentfulNavbar {
                        name
                        menuItems {
                            name
                            item {
                                url
                                title
                            }
                        }
                    }
                    ... on ContentfulHeader {
                        ...Header
                    }
                }
            }
            aboveTheFoldCtaText
            jobBoardTitle
            barwidget {
                title1
                line1
                title2
                line2
                title3
                line3
                logo {
                    fluid(maxWidth: 688, quality: 100) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
            }
            motivatedTitle
            motivatedDescription {
                motivatedDescription
            }
            smartTitle
            smartDescription {
                smartDescription
            }
            humbleTitle
            humbleDescription {
                humbleDescription
            }
            growthMindsetTitle
            growthMindsetDescription {
                growthMindsetDescription
            }
            growingImage {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            growingTitle
            growingContent {
                growingContent
            }
            meetIwocaTitle
            meetIwocaContent {
                meetIwocaContent
            }
            meetIwocaImage1 {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            meetIwocaImage2 {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            meetIwocaImage3 {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            meetIwocaProfile {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            meetIwocaQuote {
                meetIwocaQuote
            }
            meetIwocaQuoted
            workWithUsTitle
            workWithUsImage1 {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            workWithUsImage2 {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            workWithUsImage3 {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            workWithUsImage4 {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            workWithUsImage5 {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            workWithUsImage6 {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            reviewsGlassdoorIcon {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            reviewsRecommend
            reviewsApprove
            cantFindJobTitle
            cantFindJobContent
            faqSection {
                ...faqSection
            }
        }

        jobs: allWorkableJob {
            edges {
                node {
                    id
                    title
                    department
                    url
                    location {
                        country
                        city
                        telecommuting
                    }
                }
            }
        }
    }
`;
