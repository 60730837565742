import React, { useState } from 'react';
import classnames from 'classnames';
import styles from './DeptSelector.module.scss';
import { getCountryBuild } from '../../../../../util/localization';
import { useIntl } from 'react-intl';
import messages from './DepartmentSelector.messages';

const DepartmentSelector = ({ departments, setDepartment, jobTable }) => {
    const { formatMessage } = useIntl();
    const allText = getCountryBuild() === 'uk' ? 'All' : 'Alle';
    const [currDepartment, setCurrDepartment] = useState(allText);

    const handleDeptItemClick = (department) => {
        setDepartment(department);
        setCurrDepartment(department);
    };

    const setButtonStyle = (department) => {
        return department === currDepartment ? styles.ActiveDeptButton : '';
    };

    return (
        <div className={styles.JobSelector}>
            {departments.map((department) => {
                return (
                    <button
                        className={classnames(
                            styles.DepartmentButton,
                            setButtonStyle(department)
                        )}
                        onClick={() => handleDeptItemClick(department)}>
                        <div className={styles.DepartmentText}>
                            <h6>{department}</h6>
                            <p>
                                {jobTable[department].length}{' '}
                                {formatMessage({
                                    ...messages.positions
                                })}
                            </p>
                        </div>
                    </button>
                );
            })}
        </div>
    );
};

export default DepartmentSelector;
