import React from 'react';
import GlassdoorSVG from './GlassdoorSVG';
import styles from './BarWidget.module.scss';

const BarWidget = ({ title1, line1, title2, line2, title3, line3, logo }) => {
    return (
        <div className={styles.BarWidget}>
            <div className={styles.StatBlock}>
                <div className={styles.Tagline}>{title1}</div>
                <h3>{line1}</h3>
            </div>
            <div className={styles.StatBlock}>
                <div className={styles.Tagline}>{title2}</div>
                <h3>{line2}</h3>
            </div>
            <div className={styles.StatBlock}>
                <div className={styles.Tagline}>{title3}</div>
                <h3>{line3}</h3>
            </div>
            <div className={styles.StatBlock}>
                <GlassdoorSVG />
            </div>
        </div>
    );
};

export default BarWidget;
