import { defineMessages } from 'react-intl';

export default defineMessages({
    openings_in: {
        id: '_.careers__openings_in',
        defaultMessage: 'openings in'
    },
    locations: {
        id: '_.careers__locations',
        defaultMessage: 'locations'
    },
    see_life_at_iwoca: {
        id: '_.careers__see_life_at_iwoca',
        defaultMessage: 'See life at iwoca'
    },
    read_about_us: {
        id: '_.careers__read_about_us',
        defaultMessage: 'Read about us'
    },
    our_values: {
        id: '_.careers__our_values',
        defaultMessage: 'Our values'
    },
    see_all_jobs: {
        id: '_.careers__see_all_jobs',
        defaultMessage: 'See all jobs'
    },
    benefits: {
        id: '_.careers__benefits',
        defaultMessage: 'Benefits'
    },
    apply: {
        id: '_.careers__apply',
        defaultMessage: 'Apply'
    },
    recommend_to_a_friend: {
        id: '_.careers__recommend_to_a_friend',
        defaultMessage: 'Recommend to a friend'
    },
    approve_of_ceo: {
        id: '_.careers__approve_of_ceo',
        defaultMessage: 'Approve of CEO'
    }
});
