import React from 'react';
import GatsbyImg from 'gatsby-image';
import { LongArch, BasicDoughnut } from '../../../../SVGComponents/shapes';
import styles from './WorkWithUs.module.scss';

const WorkWithUs = ({
    title,
    image1,
    image2,
    image3,
    image4,
    image5,
    image6
}) => {
    return (
        <div className={styles.WorkWithUs}>
            <LongArch
                className={styles.LongArch}
                rotate="right"
                color="london"
            />
            <BasicDoughnut
                className={styles.BasicDoughnut}
                size="xs"
                color="denim"
            />
            <h2>{title}</h2>
            <div className={styles.Divider}></div>

            <div className={styles.ImagesContainer}>
                <GatsbyImg className={styles.image1} fluid={image1.fluid} />
                <GatsbyImg className={styles.image2} fluid={image2.fluid} />
                <GatsbyImg className={styles.image3} fluid={image3.fluid} />
                <GatsbyImg className={styles.image4} fluid={image4.fluid} />
                <GatsbyImg className={styles.image5} fluid={image5.fluid} />
                <GatsbyImg className={styles.image6} fluid={image6.fluid} />
            </div>
        </div>
    );
};

export default WorkWithUs;
